
import {
  mortgageMapState,
  mortgageMapGetters,
  mortgageMapActions,
  mortgageMapMutations
} from "@/store/modules/mortgage";
import Vue from "vue";
import { objectDifference } from "../../../helpers/objectDifference";
import * as types from "@/store/mutation-types";
import { FormBlock } from "../../../components/FormBuilder/types";
import { mortgageForm } from "@/forms/admin/mortgage";
import { cloneDeep } from "lodash";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface IEditMortgage {
  errorMessage: string;
  successMessage: string;
  initialized: boolean;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
  validationData: any;
  loadingText: string;
}
export default Vue.extend({
  name: "EditMortgage",
  mixins: [ActivitiesMixin],
  data(): IEditMortgage {
    return {
      errorMessage: "",
      successMessage: "",
      initialized: false,
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The mortgage you are looking for cannot be found.",
      validationData: {
        formIsValid: false
      },
      loadingText: ""
    };
  },
  created() {
    this.getMortgage(this.mortgageId)
      .then((values: any) => {
        if (!values) {
          this.errorMessage = "Error retrieving mortgage data";
          this.resourceNotFound = true;
        }
        this.SET_EDIT(values.data);
        this.initialized = true;
      })
      .catch(error => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      });
  },
  computed: {
    ...mortgageMapGetters(["getEditingMortgage", "getMortgageById"]),
    ...mortgageMapState(["editing", "makingApiRequest"]),
    updatedFields(): any {
      const edited = this.getEditingMortgage;
      const original = this.mortgage;
      return edited && original
        ? objectDifference(edited, original, ["shortName"])
        : {};
    },
    disablePrimaryToolbar(): boolean {
      return Object.keys(this.updatedFields).length === 0;
    },
    mortgage(): any {
      return this.getMortgageById(this.mortgageId);
    },
    mortgageId(): string {
      return this.$route.params.mortgageId;
    },
    formFields(): FormBlock[] {
      return mortgageForm;
    },
    formIsValid(): boolean {
      return !!this.validationData.formIsValid;
    },
    queryOverride(): any {
      return {
        "data.mortgageId": this.editing?._id,
        activityType: "MortgageActivity"
      };
    }
  },
  methods: {
    ...mortgageMapActions(["updateMortgage", "getMortgage"]),
    ...mortgageMapMutations(["SET_EDIT"]),
    ...mortgageMapMutations({ editField: types.SET_EDIT_FIELD }),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "update":
          this.submit();
          break;
        case "cancel":
          this.cancelCallback();
          break;
      }
    },
    async cancelCallback(): Promise<any> {
      if (this.$isCurrentUserAdmin || this.$isCurrentUserUnderwriter) {
        this.$router.push("/admin/mortgages").catch(() => {});
      } else {
        this.$router.push("/mortgages").catch(() => {});
      }
    },
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    },
    async submit(): Promise<void> {
      try {
        if (this.formIsValid) {
          this.loadingText = "Saving data. Please wait...";
          let fieldsUpdated = cloneDeep(this.updatedFields);
          await this.updateMortgage({
            id: this.mortgageId,
            update: fieldsUpdated
          });
          this.$appNotifySuccess("Mortgage updated successfully");
          if (this.$isCurrentUserAdmin || this.$isCurrentUserUnderwriter) {
            this.$router.replace("/admin/mortgages").catch(() => {});
          } else {
            this.$router.replace("/mortgages").catch(() => {});
          }
        } else {
          this.errorMessage = "Please Fill All Required Fields.";
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    }
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  }
});
